import React from "react";




export default function VoteDashboard (props){




    return(

    <div id="dashcontent">

        <div className="overviewDash">


            <div className="glassCover">
                <p className="comingSoon"> Coming Soon </p>
                <div className="circle topLeftC"></div>
                <div className="circle topRightC"></div>
                <div className="circle bottomLeftC"></div>
                <div className="circle bottomRightC"></div>
            </div>


        </div>

    </div>


    )
}