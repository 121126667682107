
import React from "react"
import './App.css';
import 'semantic-ui-css/semantic.min.css';


import Mainbody from "./Mainbody";


function Page() {
  return (
    <div className="App">

      <Mainbody/>

    </div>
  );
}

export default Page;
